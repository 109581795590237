import React from "react";
import ClientSlider from "../components/ClientSlider";

const Clients = ({
    title,
    subtitle,
    list1,
    list2,
}) => {

    
    return (
        <div className="bg-client">
            <div className="container">
                <div className="row marg50">
                    <div className="col-lg-12">
                        <div className="promo-white">{title}</div>
                        <div className="promo-p-white bg-white">{subtitle}</div>
                    </div>
                </div>
                <div className="row marg50">
                    <div className="col-lg-12">
                        <ClientSlider 
                            items={list1}
                            autoPlayDirection="rtl"
                        />
                    </div>
                    <div className="col-lg-12 marg50">
                        <ClientSlider
                            items={list2}
                            autoPlayDirection="ltr"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Clients;