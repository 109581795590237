import React, { Fragment } from "react";

export const newlineToBrTag = (text) => {
    let textItems = [];

    text.split('\n').map(item => {
        textItems.push(<Fragment>{item}<br/></Fragment>);
    });

    return textItems;
}