import React from "react";
import { Helmet } from "react-helmet"

import Layout from "../components/Layout";

import TACLSplash from "../index-sections/TACLSplash";
import ParallaxGap from "../index-sections/ParallaxGap";
import AboutUs from "../index-sections/AboutUs";
import Clients from "../index-sections/Clients";
import AboutDirector from "../index-sections/AboutDirector";
import ContactInfo from "../index-sections/ContactInfo";
import ContactForm from "../index-sections/ContactForm";
import History from "../index-sections/History";

const index = ({ data, dispatch }) => {
    
    const root = data.allContentfulHome.nodes[0];

    const seoTitle = root.seoTitle;
    const seoDescription = root.seoDescription;
    const seoKeywords = root.seoKeywords.join(", ");

    console.log(seoKeywords);

    const taclProps = {
        slogan: root.slogan.slogan,
        trainingDescription: root.trainingDescription.trainingDescription,
        analysisDescription: root.analysisDescription.analysisDescription,
        complianceDescription: root.complianceDescription.complianceDescription,
        legalDescription: root.legalDescription.legalDescription,
    };

    const aboutUsProps = {
        text: root.aboutUsText.aboutUsText,
        images: root.aboutUsImages.map(x => x.file.url),
    };

    const clientsProps = {
        title: root.clientListTitle,
        subtitle: root.clientListSubtitle,
        list1: root.clientList1,
        list2: root.clientList2,
    };

    const aboutDirectorProps = {
        title: root.aboutNigelTitle,
        subtitle: root.aboutNigelSubtitle,
        text: root.aboutNigelText.aboutNigelText,
        image: root.aboutNigelImage.file.url,
    };

    const contactInfoProps = {
        title: root.contactTitle,
        subtitle: root.contactSubtitle,

        emailTitle: root.contactEmailTitle,
        emailText: root.contactEmailText,
        emailSubtext: root.contactEmailSubtext,

        addressTitle: root.contactAddressTitle,
        addressText: root.contactAddressText.contactAddressText,

        callUsTitle: root.contactCallUsTitle,
        callUsText: root.contactCallUsText,
        callUsSubtext: root.contactCallUsSubtext,
    };

    const historyProps = {
        title: root.historyByNumbersTitle,
        items: [
            {
                title: root.historyByNumbers1Title,
                subtitle: root.historyByNumbers1Subtitle,
                icon: root.historyByNumbers1Icon,
            },
            {
                title: root.historyByNumbers2Title,
                subtitle: root.historyByNumbers2Subtitle,
                icon: root.historyByNumbers2Icon,
            },
            {
                title: root.historyByNumbers3Title,
                subtitle: root.historyByNumbers3Subtitle,
                icon: root.historyByNumbers3Icon,
            },
            {
                title: root.historyByNumbers4Title,
                subtitle: root.historyByNumbers4Subtitle,
                icon: root.historyByNumbers4Icon,
            },
        ]
    };

    return (
        <Layout stickyHeader={true}>
          <Helmet>
            <title>{seoTitle}</title>
            <meta name="description" content={seoDescription} />
            <meta name="keywords" content={seoKeywords} />
          </Helmet>
          <TACLSplash {...taclProps} />
          <ParallaxGap />
          <AboutUs {...aboutUsProps} />
          <Clients {...clientsProps} />
          <AboutDirector {...aboutDirectorProps} />
          <ContactInfo {...contactInfoProps} />
          <ContactForm />
          <History {...historyProps} />
        </Layout>
    );
}

export const query = graphql`
  query {
    allContentfulHome {
      nodes {
        seoTitle,
        seoDescription,
        seoKeywords,
        
        slogan {
          slogan
        },
        
        trainingDescription {
          trainingDescription
        },
        analysisDescription {
          analysisDescription
        },
        complianceDescription {
          complianceDescription
        },
        legalDescription {
          legalDescription
        },
        
        aboutUsText {
          aboutUsText,
        },
        aboutUsImages {
          file {
            url
          }
        },
        
        clientListTitle,
        clientListSubtitle,
        clientList1 {
          title
          file {
            url
          }
        },
        clientList2 {
          title
          file {
            url
          }
        },
        
        aboutNigelTitle,
        aboutNigelSubtitle,
        aboutNigelText {
          aboutNigelText
        },
        aboutNigelImage {
          file {
            url
          },
        },
        
        contactTitle,
        contactSubtitle,
        contactEmailTitle,
        contactEmailText,
        contactEmailSubtext,
        contactAddressTitle,
        contactAddressText {
          contactAddressText
        },
        contactCallUsTitle,
        contactCallUsText,
        contactCallUsSubtext,
        
        historyByNumbersTitle,
        historyByNumbers1Title,
        historyByNumbers1Subtitle,
        historyByNumbers1Icon,
        
        historyByNumbers2Title,
        historyByNumbers2Subtitle,
        historyByNumbers2Icon,
        
        historyByNumbers3Title,
        historyByNumbers3Subtitle,
        historyByNumbers3Icon,

        historyByNumbers4Title,
        historyByNumbers4Subtitle,
        historyByNumbers4Icon,
      }
    }
  }
`

export default index;