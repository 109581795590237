import React from "react";

import { newlineToBrTag } from "../helpers/textHelpers"

const ContactInfo = ({
    title,
    subtitle,
    emailTitle,
    emailText,
    emailSubtext,
    addressTitle,
    addressText,
    callUsTitle,
    callUsText,
    callUsSubtext,
 }) => {
    return (
        <div className="parallax-2 contact" id="contact">
            <div className="parallax-over-2">
                <div className="container">
                    <div className="row marg75">
                        <div className="col-lg-12">
                            <div className="promo-white">{title}</div>
                            <div className="promo-p bg-white ">{subtitle}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 marg50">
                            <div className="contact-info">
                                <div className="contact-icon"><i className="icon-envelope"></i></div>
                                <div className="contact-bg">
                                    <div className="contact-name">{emailTitle}</div>
                                    <div className="contact-tel">
                                        {emailText}
                                        <br />
                                        <span>{emailSubtext}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 marg50">
                            <div className="contact-info">
                                <div className="contact-icon cc2"><i className="icon-pointer"></i></div>
                                <div className="contact-bg">
                                    <div className="contact-name">{addressTitle}</div>
                                    <div className="contact-tel">
                                        {newlineToBrTag(addressText)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 marg50">
                            <div className="contact-info">
                                <div className="contact-icon cc3"><i className="icon-call-in"></i></div>
                                <div className="contact-bg">
                                    <div className="contact-name">{callUsTitle}</div>
                                    <div className="contact-tel">
                                        {callUsText}
                                        <br/>
                                        <span>{callUsSubtext}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;