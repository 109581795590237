import React from "react";

const History = ({ title, items }) => {
    return (
        <div className="bg-image-1">
            <div className="bg-image-toner-1">
                <div className="container">
                    <div className="row marg75">
                        <div className="col-lg-12">
                            <div className="promo-white">{title}</div>
                        </div>
                    </div>
                    <div className="row">
                        {items.map((item, i) => {
                            const className = `fact-icon f${i+1}`;
                            return (
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 col-ms-12 marg50">
                                    <div className="fact-block">
                                        <div className={className}>
                                            <i className={item.icon}></i>
                                        </div>
                                        <div className="fact-name">{item.title}</div>
                                        <div className="fact-text">{item.subtitle}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;