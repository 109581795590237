import React from "react";

const ParallaxGap = ({ }) => {
    return (
        <div className="parallax-1 hidden-sm hidden-xs">
            <div className="parallax-over-1">
                <div className="container">
                <div className="row marg75">
                    <div className="col-lg-12">
                    <br />&nbsp;
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ParallaxGap;