import React from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css"

const ClientSlider = ({
    items,
    autoPlayDirection,
}) => {

    const responsive = {
        0: { 
            items: 2,
        },
        768: {
            items: 3,
        },
        1024: {
            items: 4,
        },
        1920: {
            items: 5
        }
    };

    return (
        <AliceCarousel
            infinite={true}
            autoPlay={true}
            autoPlayInterval={2000}
            autoPlayDirection={autoPlayDirection}
            buttonsDisabled={true}
            dotsDisabled={true}
            mouseTrackingEnabled={true}
            controlsStrategy={'responsive'}
            responsive={responsive}
        >
        {items.map((item, i) => {
            console.log(item);
            return (
                <div
                    key={i} 
                    className="slide" 
                    style={{ 
                        maxHeight: "100%",
                        maxWidth: "100%",
                        height: "100px",
                        width: "200px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "20px",
                    }}
                >
                    <img
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        src={item.file.url}
                        alt={item.title}
                    />
                </div>
            );
        })}
    </AliceCarousel>
    );
};

export default ClientSlider;