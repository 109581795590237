import React, { Fragment } from "react";

import { newlineToBrTag } from "../helpers/textHelpers";

const AboutDirector = ({
    title,
    subtitle,
    text,
    image,
 }) => {
    return (
        <div className="bg team" id="team">
            <div className="container">
                <div className="row marg50">
                    <div className="col-lg-12">
                        <div className="promo">{title}</div>
                        <div className="promo-p">{subtitle}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 marg65">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-ms-12">
                                <div className="team-text">
                                    <img src={image} alt="Nigel Kirkwood" style={{ float: "left", paddingRight: "25px" }} width="300" heigh="100%" />
                                    
                                    {newlineToBrTag(text)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutDirector;