import React, { useState } from "react";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const ContactForm = ({ }) => {
    const [name, setName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [subject, setSubject] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const [emailSending, setEmailSending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const sendEmailEnquiry = (event) => 
    {
        setEmailSending(true);
        setEmailError(false);
        setEmailSent(false);

        try {
            const postBody = {
                "toEmails": ["NigelKirkwood@digital-tachograph.com", "declan@dowling.digital"],
                "replyToEmails": [email],
                "subject": `Website Enquiry: ${subject}`,
                "message": 
                `
                    <p>Name: ${name}</p>
                    <p>Email: ${email}</p>
                    <p>Subject: ${subject}</p>
                    <br/>

                    <p>
                        Message:<br/><br/>
                        ${message}
                    </p>
                `
            };

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postBody)
            };

            fetch('https://dtyme0vq1l.execute-api.eu-west-1.amazonaws.com/Prod/send', requestOptions)
            .then(response => response.status === 204 ? setEmailSent(true) : null);

            setName(undefined);
            setEmail(undefined);
            setSubject(undefined);
            setMessage(undefined);

        } catch (err) {
            setEmailError(true);
        } finally {
            setEmailSending(false);
        }
        
        event.preventDefault();
    };

    return (
        <div className="bg">
            <div className="container">

                <div className="marg75" />

                {emailSent && 
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-ms-12">
                        <div class="alert alert-success" role="alert">
                            <strong>Thank you</strong> - Your email has been sent.
                        </div>
                    </div>
                </div>}

                {!!emailError && 
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-ms-12">
                        <div class="alert alert-danger" role="alert">
                            <strong>Something went wrong</strong> - Please try again later.
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-ms-12">
                        <div className="row">
                            <form id="contactForm" onSubmit={sendEmailEnquiry}>
                                <div className="col-lg-12">
                                    <p className="text_cont">
                                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" placeholder="Name" className="input-cont"/>
                                    </p>
                                    <div className="alert alert-danger error" id="nameError">
                                        <i className="icon-ban"></i> Oh snap! Name field can't stay empty.
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="text_cont">
                                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="email" placeholder="E-mail" className="input-cont"/>
                                    </p>
                                    <div className="alert alert-danger error" id="emailError">
                                        <i className="icon-ban"></i> Oh snap! There was a mistake when writing a e-mail.
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="text_cont">
                                        <input value={subject} onChange={(e) => setSubject(e.target.value)} type="text" name="subject" placeholder="Subject" className="input-cont"/>
                                    </p>
                                    <div className="alert alert-danger error" id="subjectError">
                                        <i className="icon-ban"></i> Oh snap! Subject field can't stay empty.
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="text_cont">
                                        <textarea value={message} onChange={(e) => setMessage(e.target.value)}  name="message" placeholder="Message" id="message" className="input-cont-textarea" cols="30" rows="8"></textarea>
                                    </p>
                                    <div className="alert alert-danger error" id="messageError">
                                        <i className="icon-ban"></i> Oh snap! This field can't stay empty.
                                    </div>
                                    <div className="alert alert-success success" id="success">
                                        <i className="icon-check"></i> Well done! You message is successfully sent.
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p>
                                        {
                                            emailSending === true
                                            ? <Loader type="Grid" color="#144398" height={40} width={40} /> 
                                            : <input type="submit" id="send" className="btn btn-send" value="Send message" />
                                        }
                                    </p>
                                </div>

                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-ms-12">
                        <iframe width="100%" height="380" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2367.46777239348!2d-2.8447142841518662!3d53.60295758003362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b1691bdf25a75%3A0xa27071a8faf2fb6d!2sTachograph+Analysis+Consultants+Limited+(TACL)!5e0!3m2!1sen!2suk!4v1547032253584"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;