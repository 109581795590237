import React from "react";
import AliceCarousel from "react-alice-carousel";

import { newlineToBrTag } from "../helpers/textHelpers";

import "react-alice-carousel/lib/alice-carousel.css";

const AboutUs = ({ text, images }) => {
    return (
        <div className="bg about" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 marg50">
                        <section className="slider">
                            <div className="flexslider">
                                <div className="slides">
                                    <AliceCarousel
                                        autoPlay={true}
                                        autoPlayInterval={2000}
                                        buttonsDisabled={true}
                                    >
                                        {images.map((image, i) => {
                                            return (<img src={image} key={i} />);
                                        })}
                                    </AliceCarousel>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-lg-6 col-md-6 marg50">
                        <div className="about-block">
                            <div className="about-name">About us</div>
                            <div className="about-text">
                                {newlineToBrTag(text)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;