import React from "react";
import { Link } from "gatsby";

import { newlineToBrTag } from "../helpers/textHelpers";


const TACLSplash = ({
    slogan,
    trainingDescription,
    analysisDescription,
    complianceDescription,
    legalDescription,
}) => {
    return (
        <div className="serv-bg service" id="services">
            <div className="container" id="service-scroll">
                <div className="row marg75">
                    <div className="col-lg-12">
                        <div className="promo-mini">
                            {newlineToBrTag(slogan)}
                        </div>
                    </div>
                </div>
                <div className="row marg50">
                    <div className="col-lg-12">
                        <div className="serv-3 serv-6 serv-12">
                            <div className="serv-block sb1">
                                <div className="serv-icon s1">
                                    <img src={"../../images/tacl-t.jpg"} width="64" height="53" alt="T" />
                                </div>
                                <div className="serv-name">Training</div>
                                <div className="serv-desc">
                                    {newlineToBrTag(trainingDescription)}
                                    <br/><br/><br/>
                                    <div className="sd-medium-btn sd-training-btn">
                                    <Link to="training/">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serv-3 serv-6 serv-12">
                            <div className="serv-block sb2">
                                <div className="serv-icon s2">
                                    <img src={"../../images/tacl-a.jpg"} width="64" height="53" alt="A" />
                                </div>
                                <div className="serv-name">Analysis</div>
                                <div className="serv-desc">
                                    {newlineToBrTag(analysisDescription)}
                                    <br/><br/><br/>
                                    <div className="sd-medium-btn sd-analysis-btn">
                                    <Link to="analysis/">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serv-3 serv-6 serv-12">
                            <div className="serv-block sb3">
                                <div className="serv-icon s3">
                                    <img src={"../../images/tacl-c.jpg"} width="64" height="53" alt="C" />
                                </div>
                                <div className="serv-name">Compliance</div>
                                <div className="serv-desc">
                                    {newlineToBrTag(complianceDescription)}
                                    <br/><br/><br/>
                                    <div className="sd-medium-btn sd-compliance-btn">
                                    <Link to="compliance/">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serv-3 serv-6 serv-12">
                            <div className="serv-block sb4">
                                <div className="serv-icon s4">
                                    <img src={"../../images/tacl-l.jpg"} width="64" height="53" alt="L" />
                                </div>
                                <div className="serv-name">Legal</div>
                                <div className="serv-desc">
                                    {newlineToBrTag(legalDescription)}
                                    <br/><br/><br/>
                                    <div className="sd-medium-btn sd-legal-btn">
                                    <Link to="legal/">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TACLSplash;